import React from "react";
import { Link } from "react-router-dom";

function CardNavbar(props) {
  return (
    <div className="row">
      <div className="col">
        <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-4">
          <ol className="breadcrumb mb-0">
            <Link className="breadcrumb-item" to={`/${props.type || "home"}`}>
              {props.type || "Bewerbungen"}
            </Link>
            <li className="breadcrumb-item active" aria-current="page">
              ({props.id})
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
}

export default CardNavbar;
