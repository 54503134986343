import React, { useEffect, useState } from 'react';
import NavBar from '../Components/Navbar';
import auth from '../services/authService';
import { Navigate } from 'react-router-dom';
import Positions from '../Components/positions';

function Jobs() {
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = auth.getCurrentUser();
    setUser(user);
    if (!auth.getCurrentUser()) {
      <Navigate to="/login" replace />;
      return () => {};
    }
  }, []);

  return (
    <React.Fragment>
      <NavBar user={user} />
      <Positions user={user} />
    </React.Fragment>
  );
}

export default Jobs;
