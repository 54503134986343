import React from "react";
import InputButton from "../../commons/InputButton";
import NavigationButtons from "../../commons/NavigationButtons";
import { Step3Antworten, StepsFragen } from "../../../utils/strings";

function BerufserfahrungStep({ beruf }) {
  // beruf SOLLTE IN DATIV ÜBERGEGEBEN WERDEN
  return (
    <div>
      <h2>{StepsFragen[2]}</h2>
      <InputButton
        label={Step3Antworten[0]}
        value={Step3Antworten[0]}
        valueKey="berufserfahrung"
      />
      <InputButton
        label={Step3Antworten[1]}
        value={Step3Antworten[1]}
        valueKey="berufserfahrung"
      />
      <InputButton
        label={Step3Antworten[2]}
        value={Step3Antworten[2]}
        valueKey="berufserfahrung"
      />
      <InputButton
        label={Step3Antworten[3]}
        value={Step3Antworten[3]}
        valueKey="berufserfahrung"
      />

      <NavigationButtons />
    </div>
  );
}

BerufserfahrungStep.defaultProps = {
  beruf: "der Branche",
};

export default BerufserfahrungStep;
