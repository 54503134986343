import React from "react";

const ListGroup = (props) => {
  const { items, textProperty, valueProperty, onItemSelect, selectedItem } =
    props;
  return (
    <React.Fragment>
      <ul className="list-group">
        {items.map((item) => (
          <li
            key={item[valueProperty] ? item[valueProperty] : 0}
            onClick={() => onItemSelect(item)}
            className={
              selectedItem === item
                ? "list-group-item active clickable"
                : "list-group-item clickable"
            }
          >
            {item[textProperty]}
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

ListGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "_id",
};

export default ListGroup;
