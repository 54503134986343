import http from "./httpService";
const apiEndPoint = "/positions";

function PositionUrl(positionId) {
  return `${apiEndPoint}/${positionId}`;
}

export function getPositions() {
  return http.get(apiEndPoint);
}

export async function savePosition(position) {
  if (position._id) {
    console.log(position);
    const body = { ...position };
    delete body._id;
    delete body.added;
    console.log(body);
    return http.put(PositionUrl(position._id), body);
  }
  console.log(position);
  return http.post(apiEndPoint, {
    name: position.name,
    caption: position.caption,
    sex: position.sex,
  });
}
export function getPosition(positionID) {
  // console.log(PositionUrl(positionID));
  return http.get(PositionUrl(positionID));
}

export function deletetPosition(positionID) {
  return http.delete(PositionUrl(positionID));
}
