import React from "react";
import InputButton from "../../commons/InputButton";
import NavigationButtons from "../../commons/NavigationButtons";
import { Step1Antworten, StepsFragen } from "../../../utils/strings";

// Value should be sent as boolean to server (0/1/2)
function AusbildungStep(props) {
  return (
    <div>
      <h2>{StepsFragen[0]}</h2>

      <InputButton label={Step1Antworten[0]} value="1" valueKey="ausbildung" />
      <InputButton label={Step1Antworten[1]} value="2" valueKey="ausbildung" />
      <InputButton label={Step1Antworten[2]} value="0" valueKey="ausbildung" />
      <NavigationButtons />
    </div>
  );
}

export default AusbildungStep;
