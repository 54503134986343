import React from "react";
import UserInfos from "./UserInfos";

function CardRightSide(props) {
  return (
    <div className="col-lg-8">
      <UserInfos user={props.user} />
    </div>
  );
}

export default CardRightSide;
