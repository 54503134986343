import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { multiStepContext } from '../../context/StepContext';
import { Button } from '@mui/material';
import './inputButton.css';

function InputButton({ label, value, valueKey }) {
  const { setStep, userData, setUserData, currentStep } =
    useContext(multiStepContext);
  // console.log(valueKey);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const width = isMobile ? 200 : 500;
  // console.log('Button width', width);
  // onClick function should be provided as a Prop
  return (
    <div>
      <Button
        className="buttons"
        sx={{ width: width, height: 120 }}
        variant="outlined"
        color="primary"
        value={value}
        onClick={(e) => {
          const data = { ...userData };
          data[valueKey] = e.target.value;
          setUserData(data);
          setStep(currentStep + 1);
        }}
      >
        {label}
      </Button>
    </div>
  );
}

export default InputButton;
