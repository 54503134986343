import http from './httpService';
import { getAbgesagter } from './absageService';
import { getZugesagter } from './zusageService';
const apiEndPoint = '/bewerbung';

function bewerberUrl(bewerberId) {
  return `${apiEndPoint}/${bewerberId}`;
}

export async function send(bewerbung) {
  return await http.post(apiEndPoint, {
    anrede: bewerbung.anrede,
    firstname: bewerbung.firstname,
    lastname: bewerbung.lastname,
    email: bewerbung.email,
    number: bewerbung.number,
    plz: bewerbung.plz,
    position: bewerbung.position,
    ausbildung: bewerbung.ausbildung,
    fuehrerschein: bewerbung.fuehrerschein,
    berufserfahrung: bewerbung.berufserfahrung,
    einstieg: bewerbung.einstieg,
    datenschutz: bewerbung.datenschutz,
  });
}

export function getBewerbungen() {
  return http.get(apiEndPoint);
}

export function absagen(bewerberId) {
  return http.put(bewerberUrl(bewerberId), { abgesagt: '1' });
}

export function zusagen(bewerberId) {
  return http.put(bewerberUrl(bewerberId), { abgesagt: '0' });
}

export function deleteBewerber(bewerberId) {
  return http.delete(bewerberUrl(bewerberId));
}

export async function getBewerber(type, bewerberId) {
  if (type === 'absagen') return await getAbgesagter(bewerberId);
  else if (type === 'zusagen') return await getZugesagter(bewerberId);
  return http.get(bewerberUrl(bewerberId));
}
