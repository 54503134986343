import React, { useState } from "react";

function DeleteBtn(props) {
  const { onClick } = props;
  const [timePressed, setTimePressed] = useState(0);
  const str = ["Delete", "Sicher?", "Löschen"];

  function test() {
    if (timePressed >= 2) return onClick();
    setTimePressed(timePressed + 1);
    // console.log(timePressed);
  }
  return (
    <button onClick={test} className="btn btn-danger btn-sm">
      {str[timePressed]}
    </button>
  );
}

export default DeleteBtn;
