import React, { useEffect } from "react";
import auth from "../services/authService";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    auth.logout();
    navigate(`/login`, { replace: true });
    navigate(0); // to refresh the page
  });
  return <div>logout</div>;
}

export default Logout;
