import React, { useContext } from 'react';
import { Checkbox } from '@mui/material';
import { multiStepContext } from '../../context/StepContext';
import { Link } from 'react-router-dom';

function InputCheckBox({ label, valueKey }) {
  const { userData, setUserData } = useContext(multiStepContext);

  return (
    <div>
      <Checkbox
        sx={{ my: 1 }}
        onChange={(e) => {
          const data = { ...userData };
          data[valueKey] = e.target.checked ? '1' : '0';
          setUserData(data);
        }}
      />
      <Link to={'/datenschutz'}>
        <label className="clickable" style={{ width: '200px' }}>
          {label}
        </label>
      </Link>
    </div>
  );
}

export default InputCheckBox;
