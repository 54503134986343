import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../Components/Navbar';
import auth from '../services/authService';
import Bewerbungen from '../Components/bewerbungen';
import { Navigate } from 'react-router-dom';
import { multiStepContext } from '../context/StepContext';
import Test from './Test';

function Home() {
  const [user, setUser] = useState({});
  const { show, setShow } = useContext(multiStepContext);

  useEffect(() => {
    const user = auth.getCurrentUser();
    setUser(user);
    if (!auth.getCurrentUser()) {
      <Navigate to="/login" replace />;
      return () => {};
    }
  }, []);

  function renderTable() {
    if (show === 'absagen') return <Test />;
    return <Bewerbungen user={user} />;
  }

  return (
    <React.Fragment>
      <NavBar user={user} />
      {renderTable()}
    </React.Fragment>
  );
}

export default Home;
