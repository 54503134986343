import React, { useContext } from 'react';
import { multiStepContext } from '../../context/StepContext';
import { Button } from '@mui/material';

function NavigationButtons({ last, next}) {
  // console.log(last);
  const { setStep, submitData, currentStep } = useContext(multiStepContext);
  const renderNextButton = () => {
    if (last)
      return (
        <Button variant="contained" color="primary" onClick={submitData}>
          Submit
        </Button>
      );
    else
      return (
        <>
        {next && (<Button
          variant="contained"
          color="primary"
          onClick={() => setStep(currentStep + 1)}
        >
          Next
        </Button>)}
        </>
        
      );
  };
  return (
    <div>
      {currentStep > 1 && (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setStep(currentStep - 1)}
          >
            Back
          </Button>
          <span> </span>
        </>
      )}
      {renderNextButton()}
    </div>
  );
}
NavigationButtons.defaultProps = {
  last: false,
  next: false,
};
export default NavigationButtons;
