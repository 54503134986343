import React from "react";
import InputButton from "../../commons/InputButton";
import NavigationButtons from "../../commons/NavigationButtons";
import { Step2Antworten, StepsFragen } from "../../../utils/strings";

// Value should be sent as boolean to server (0/1)
function FuehrerscheinStep(props) {
  return (
    <div>
      <h2>{StepsFragen[1]}</h2>
      <InputButton
        label={Step2Antworten[0]}
        value="1"
        valueKey="fuehrerschein"
      />
      <InputButton
        label={Step2Antworten[1]}
        value="0"
        valueKey="fuehrerschein"
      />
      <NavigationButtons />
    </div>
  );
}

export default FuehrerscheinStep;
