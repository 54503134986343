export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function jaOderNein(wert) {
  if (wert === "2") return "Ja, in anderem Bereich"; // if value fom ausbildung 2
  return wert === "1" ? "Ja" : "Nein";
}

export const StepsFragen = [
  "Hast Du eine abgeschlossene Ausbildung in einem der folgenden Bereiche?",
  "Besitzt Du einen Führerschein?",
  "Wie viel Berufserfahrung hast Du in der Branche?",
  "Wann könntest Du bei uns anfangen?",
  "Wie können wir Dich am besten erreichen?",
];

export const Step1Antworten = [
  "Ja, ich habe eine Ausbildung im kaufmännischen Bereich",
  "Ich habe eine Berufsaufsbildung in einem anderen Bereich (beliebige Fachrichtung)",
  "Nein, ich habe keine Ausbildung in den genannten Bereichen",
];

export const Step2Antworten = ["Ja", "Nein"];

export const Step3Antworten = [
  "1 - 3 Jahre",
  "3 - 10 Jahre",
  "Mehr als 10 Jahre",
  "Ich konnte bisher keine Berufserfahrung in diesem Bereich sammeln",
];

export const Step4Antworten = [
  "Ab Sofort",
  "In 1 - 2 Monaten",
  "In 3 - 4 Monaten",
  "Ich weiß es noch nicht",
];

export const Step5Antworten = [
  "Anrede",
  "Vorname",
  "Nachname",
  "Email",
  "Rufnummer",
  "Postleitzahl (PLZ)",
  "Ich bin damit einverstanden,dass die HD Energiesysteme GmbH meine Daten für eine Kontaktaufnahme oder eventuelle Rückfragen verwenden darf. Die Datenschutzbestimmungen akzeptiere ich.",
];
