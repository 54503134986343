import React, { useEffect, useState } from 'react';

import JobCard from '../Components/JobCard';
import { getPositions } from '../services/positionService';
import './positions.css';
import GridSystem from '../Components/commons/GridSystem';
import { Card } from 'react-bootstrap';
export default function Positions() {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    async function fetchPositions() {
      const { data } = await getPositions();
      setPositions(data);
      // console.log(data);
    }
    fetchPositions();
  }, []);

  return (
    <div id="containerDiv">
      {positions.length > 0 ? (
        <div>
          <h1 className="title"> Jetzt schnell bewerben </h1>
          <h5 className="title">in wenigen Schritten</h5>
          <GridSystem colCount={3} md={4} children={positions}>
            {positions.map((p) => (
              <JobCard key={p._id} position={p} />
            ))}
          </GridSystem>
        </div>
      ) : (
        <Card
          bg="light"
          text="dark"
          style={{ width: '25rem', height: '18rem' }}
          className="mb-3 center-card"
        >
          <Card.Header> keine Offenstellen</Card.Header>
          <Card.Body>
            <Card.Title>Schade </Card.Title>
            <Card.Text>
              Aktuell gibt es keine Offenstellen
              <br /> Versuchen Sie es bitte später noch einmal
            </Card.Text>
          </Card.Body>
        </Card>
      )}

      {/* <div className="card-group">
        {positions.map((p) => (
          <JobCard key={p._id} position={p} />
        ))}
      </div> */}
    </div>
  );
}
