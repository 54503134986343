import React from 'react';
import { Link } from 'react-router-dom';

function UserAvater(props) {
  const { user, onReject, onAccept, btns } = props;
  const avatar = require('./ava3.webp');
  const href = `mailto:${
    user.email
  }?subject=HD Energiesysteme -JOB&body=Sehr%20${
    user.anrede === 'Herr' ? 'geehrter%20Herr' : 'geehrte%20Frau'
  }%20${user.lastname},`;

  const isHired = user.abgesagt === '0';
  const isRejected = user.abgesagt === '1';

  // console.log(isRejected);

  return (
    <div className="card mb-4">
      <div className="card-body text-center">
        <img
          src={avatar}
          alt="avatar"
          className="rounded-circle img-fluid"
          style={{ width: 150 }}
        />
        <h5 className="my-3">
          {user.firstname} {user.lastname}
        </h5>
        <p className="text-muted mb-1">{user.position_name}</p>
        <p className="text-muted mb-4">{user.plz}</p>

        {btns && (
          <div className="d-flex justify-content-center m-2">
            <button
              type="button"
              className={`btn btn-${isHired ? 'success' : 'outline-success'}`}
              onClick={onAccept}
              disabled={isHired || isRejected}
            >
              {isHired ? 'Zugesagt' : 'Zusagen'}
            </button>

            <button type="button" className="btn btn-outline-primary ms-1">
              <Link className="noLink" to={href}>
                Email
              </Link>
            </button>
            <button
              type="button"
              className={`btn btn-${
                isRejected ? 'danger' : 'outline-danger'
              } ms-1`}
              onClick={() => onReject()}
              disabled={isRejected || isHired}
            >
              {isRejected ? 'Abgesagt' : 'Absagen'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserAvater;
