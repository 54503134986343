import React, { useState } from "react";
import App from "../App";
import { send } from "../services/bewerbungService";
import { toast } from "react-toastify";

export const multiStepContext = React.createContext();
function StepContext() {
  const [currentStep, setStep] = useState(1);
  const [userData, setUserData] = useState([]);
  //const [finalData, setFinalData] = useState([]);
  const [show, setShow] = useState("");

  async function submitData() {
    // setFinalData((finalData) => [...finalData, userData]);
    //setUserData('');
    console.log(userData);
    try {
      await send(userData);
      toast.success("Bewerbung geschickt!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log(ex.response);
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

    function mapValueToLabel(message) {
      const map = new Map();

      map.set("anrede", "Anrede");
      map.set("firstname", "Vorname");
      map.set("lastname", "Nachname");
      map.set("email", "Email");
      map.set("number", "Anrede");
      map.set("plz", "Postleitzahl");

      const toRemove = " is required";
      const toAdd = " ist erforderlich";
      const value = message.substr(1, message.length - toRemove.length - 2);

      return map.get(value) + toAdd;
    }

    //setStep(1);
  }
  return (
    <div>
      <multiStepContext.Provider
        value={{
          currentStep,
          setStep,
          userData,
          setUserData,
          submitData,
          show,
          setShow,
        }}
      >
        <App />
      </multiStepContext.Provider>
    </div>
  );
}

export default StepContext;
