import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Table from './commons/table/table';
import auth from '../services/authService';
import DeleteBtn from './commons/Buttons/DeleteBtn';

class PositionsTable extends Component {
  columns = [
    {
      path: 'name',
      label: 'Beruf',
      content: (b) => <Link to={`/jobs/${b._id}`}>{b.name}</Link>,
    },
    { path: 'sex', label: 'Geschlecht' },
    { path: 'added', label: 'Hinzugefügt am' },

    {
      key: 'more',
      content: (b) => (
        <Link to={`/jobs/${b._id}`}>
          <button className="btn btn-primary btn-sm">Bearbeiten</button>
        </Link>
      ),
    },
  ];
  deleteColumn = {
    key: 'delete',
    content: (position) => (
      <DeleteBtn onClick={() => this.props.onDelete(position)} />
    ),
  };
  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }
  render() {
    const { bewerber, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={bewerber}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default PositionsTable;
