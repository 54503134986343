import http from "./httpService";
const apiEndPoint = "/zusage";

function bewerberUrl(bewerberId) {
  return `${apiEndPoint}/${bewerberId}`;
}

export function getZusagen() {
  return http.get(apiEndPoint);
}

export function getZugesagter(bewerberId) {
  return http.get(bewerberUrl(bewerberId));
}
