import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { paginate } from '../utils/paginate';
import { capitalizeFirstLetter } from '../utils/strings';
import BewerberTable from './BewerberTable';
import Pagination from './commons/pagination';
import ListGroup from './commons/listGroup';
import SearchBox from './commons/searchBox';
import { deleteBewerber, getBewerbungen } from '../services/bewerbungService';
import { getPositions } from '../services/positionService';
import { getAbsagen } from '../services/absageService';
import { getZusagen } from '../services/zusageService';
import './bewerbungen.css';

class Bewerbungen extends Component {
  state = {
    data: [],
    positions: [],
    currentPage: 1,
    PAGESIZE: 4,
    searchQuery: '',
    selectedPosition: null,
    sortColumn: {
      path: 'lastname',
      order: 'asc',
    },
    timeDeletePressed: 0,
  };

  async componentDidMount() {
    if (this.props.type) {
      if (this.props.type === 'absagen') {
        const { data } = await getAbsagen();
        this.setState({ data });
        const { data: positionsData } = await getPositions();
        const positions = [{ name: 'Alle Arbeitsstellen' }, ...positionsData];
        this.setState({ data, positions, selectedPosition: positions[0] });
        return;
      }
      const { data } = await getZusagen();
      this.setState({ data });
      const { data: positionsData } = await getPositions();
      const positions = [{ name: 'Alle Arbeitsstellen' }, ...positionsData];
      this.setState({ data, positions, selectedPosition: positions[0] });
      return;
    }
    const { data } = await getBewerbungen();
    // console.log(data);
    const { data: positionsData } = await getPositions();
    const positions = [{ name: 'Alle Arbeitsstellen' }, ...positionsData];
    this.setState({ data, positions, selectedPosition: positions[0] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type !== this.state.type) {
      console.log('pokemons state has changed.');
    }
  }

  handleLike = (bewerbung) => {
    const bewerber = [...this.state.data];
    const index = bewerber.indexOf(bewerbung);
    bewerber[index] = { ...bewerber[index] };
    bewerber[index]['liked'] = !bewerber[index]['liked'];
    this.setState({ data: bewerber });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePositionSelect = (position) => {
    this.setState({
      selectedPosition: position,
      searchQuery: '',
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedPosition: null,
      currentPage: 1,
    });
  };

  getpagedDate = () => {
    const {
      PAGESIZE,
      currentPage,
      selectedPosition,
      data: allBewerber,
      sortColumn,
      searchQuery,
    } = this.state;

    let filtered = allBewerber;
    if (searchQuery)
      filtered = allBewerber.filter((b) =>
        b.lastname.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedPosition && selectedPosition._id)
      filtered = allBewerber.filter(
        (m) => m.position._id === selectedPosition._id
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const data = paginate(sorted, currentPage, PAGESIZE);
    return { totalCount: filtered.length, data };
  };

  render() {
    const { data, PAGESIZE, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    if (data.length === 0)
      return (
        <div className="container">
          <h2>
            Es gibt aktuell keine{' '}
            {this.props.type
              ? capitalizeFirstLetter(this.props.type)
              : 'Bewerbungen'}{' '}
            im Datenbank.
          </h2>
        </div>
      );
    const result = this.getpagedDate();
    const { totalCount, data: bewerber } = result;
    return (
      <div className="homeContainer">
        <div className="row">
          <div className="col-2">
            <ListGroup
              items={this.state.positions}
              onItemSelect={this.handlePositionSelect}
              selectedItem={this.state.selectedPosition}
            />
          </div>
          <div className="col">
            {user && (
              <Link
                to="/jobs/new"
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Job
              </Link>
            )}
            <h3>
              Zeigen von <span style={{ color: 'blue' }}>{totalCount}</span>{' '}
              Bewerber im Datenbank
            </h3>

            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <BewerberTable
              type={this.props.type}
              bewerber={bewerber}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onLike={this.handleLike}
              onSort={this.handleSort}
            />
            <Pagination
              itemsCount={totalCount}
              pageSize={PAGESIZE}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }

  handleDelete = async (user) => {
    if (this.props.type)
      return toast.error(
        'Du kannst ein bereits zugesagter/abgesagter Bewerber nicht löschen!'
      );
    const originalData = this.state.data;
    const data = originalData.filter((m) => m._id !== user._id);
    console.log(data);
    this.setState({ data });
    try {
      await deleteBewerber(user._id);
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 404)
        toast.error('This User has already been deleted.');
      this.setState({ data: originalData });
    }
  };
}

export default Bewerbungen;
