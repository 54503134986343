import React, { useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { multiStepContext } from '../../context/StepContext';

function SelectField({ label, valueKey, items }) {
  const { userData, setUserData } = useContext(multiStepContext);

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 750 }}>
        <InputLabel id="demo-simple-select-label" color="secondary">
          {label}
        </InputLabel>
        <Select
          color="secondary"
          defaultValue=""
          label={label}
          onChange={(e) => {
            const data = { ...userData };
            data[valueKey] = e.target.value;
            setUserData(data);
          }}
        >
          <MenuItem value={items[0]} selected={true}>
            {items[0]}
          </MenuItem>
          <MenuItem value={items[1]}>{items[1]}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectField;
