import React from 'react';
import Joi from 'joi-browser';
import Form from './commons/Form/form';
import { getPosition, savePosition } from '../services/positionService';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';

class NewJobForm extends Form {
  state = {
    data: { name: '', sex: '', caption: '' },
    sex: [
      { name: '', _id: 0 },
      { name: 'm/f/d', _id: 1 },
      { name: 'm', _id: 2 },
      { name: 'f', _id: 3 },
    ],
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().min(5).required().label('Arbeitsstelle'),
    sex: Joi.string().required().label('Geschlecht'),
    caption: Joi.string().min(10).required().label('Beschreibung'),
    added: Joi.string(),
  };

  async populatePosition() {
    try {
      this.state.positionId = this.props.params.id;
      const id = this.state.positionId;
      console.log(id);
      if (id === 'new') return;
      const { data } = await getPosition(id);
      //let tmp = data.sex;
      // console.log(tmp);
      // console.log(this.state.sex.filter((f) => f.name === data.sex)[0]._id);
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace('/not-found');
    }
  }

  async componentDidMount() {
    await this.populatePosition();
  }

  doSubmit = async () => {
    await savePosition(this.state.data);

    this.props.navigate(`/jobs`, { replace: true });
    this.props.navigate(0); // to refresh the page
  };

  // it should generate a qr code with the link of position
  generateUrl = () => {
    const url = `http://localhost:3000/bewerben/${this.state.data.name}`;
    return <QRCode value={url} viewBox={`0 0 256 256`} />;
  };
  render() {
    return (
      <div className="container bg-light rounded-3 p-3">
        <h1>{this.props.params.id === 'new' ? 'Add' : 'Edit'} Job</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('name', 'Arbeitsstelle')}
          {this.renderInput('caption', 'Beschreibung')}
          {this.renderSelect('sex', 'Geschlecht', this.state.sex)}
          {this.renderButton('Save')}
        </form>
      </div>
    );
  }
}

export function JopForm(props) {
  const params = useParams();
  const navigate = useNavigate();
  return <NewJobForm navigate={navigate} params={params}></NewJobForm>;
}

export default NewJobForm;
