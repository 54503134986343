import React from "react";
import InputField from "../../commons/InputField";
import NavigationButtons from "../../commons/NavigationButtons";
import InputCheckBox from "../../commons/InputCheckBox";
import SelectField from "../../commons/SelectField";
import { Step5Antworten, StepsFragen } from "../../../utils/strings";

function PersoInfoStep(props) {
  return (
    <div>
      <h2>{StepsFragen[4]}</h2>
      <SelectField
        label={Step5Antworten[0]}
        valueKey="anrede"
        items={["Herr", "Frau"]}
      />
      <InputField label={Step5Antworten[1]} valueKey="firstname" />
      <InputField label={Step5Antworten[2]} valueKey="lastname" />
      <InputField label={Step5Antworten[3]} valueKey="email" />
      <InputField label={Step5Antworten[4]} valueKey="number" />
      <InputField label={Step5Antworten[5]} valueKey="plz" />
      <InputCheckBox label={Step5Antworten[6]} valueKey="datenschutz" />

      <div>
        <NavigationButtons last={true} />
      </div>
    </div>
  );
}

export default PersoInfoStep;
