import React from 'react';
import { Link } from 'react-router-dom';

function JobCard(props) {
  const { position } = props;
  // console.log(props);
  const href = `/bewerben/${position.name}`;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className="card job" style={{ width: '18rem' }}>
      <div className="card-body">
        <h5 className="card-title">{capitalizeFirstLetter(position.name)}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{position.sex}</h6>
        <p className="card-text job-desc">{position.caption}</p>
        <Link to={href}>
          <button type="button" className="btn btn-primary">
            Jetzt bewerben
          </button>
        </Link>
      </div>
    </div>
  );
}

export default JobCard;
