import React, { useContext, useEffect, useState } from 'react';
import { multiStepContext } from '../../context/StepContext';
import { useMediaQuery } from 'react-responsive';

import { StepLabel, Stepper, Step } from '@mui/material';
import PersoInfoStep from './Steps/persoInfoStep';
import AusbildungStep from './Steps/AusbildungStep';
import FuehrerscheinStep from './Steps/FuehrerscheinStep';
import BerufserfahrungStep from './Steps/BerufserfahrungStep';
import EinstiegStep from './Steps/EinstiegStep';
import './stepform.css';
import { useNavigate, useParams } from 'react-router-dom';

import { getPositions } from '../../services/positionService';

function StepForm() {
  const { setStep, userData, setUserData, currentStep } =
    useContext(multiStepContext);

  const { position } = useParams();
  const [positions, setPositions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPositions() {
      const { data } = await getPositions();
      setPositions(data);
      // console.log(data);
      if (data.filter((p) => p.name === position).length === 0) {
        //console.log('No Position');

        navigate('/not-found', { replace: true });
        navigate(0); // to refresh the page
      }
    }
    fetchPositions();

    const data = { ...userData };
    data['position'] = position;
    setUserData(data);
    // console.log(position);
  }, []);

  const steps = [
    'ausbildung',
    'fuehrerschein',
    'berufserfahrung',
    'Einstieg',
    'person',
  ];
  function showStep(step) {
    switch (step) {
      case 1:
        return <AusbildungStep />;
      case 2:
        return <FuehrerscheinStep />;
      case 3:
        return <BerufserfahrungStep />;
      case 4:
        return <EinstiegStep />;
      case 5:
        return <PersoInfoStep />;
    }
  }
  const width = useMediaQuery({ query: '(max-width: 600px)' }) ? 200 : 500;
  // console.log('Stepper width', width);

  return (
    <div className="container">
      <div className="center-stepper">
        <Stepper
          style={{ width: width }}
          className="stepper"
          activeStep={currentStep - 1}
          orientation="horizontal"
        >
          {steps.map((s) => (
            <Step key={s}>
              <StepLabel key={s} />
            </Step>
          ))}
        </Stepper>
      </div>
      <div>{showStep(currentStep)}</div>
    </div>
  );
}

export default StepForm;
