import React from 'react';

// Input: liked: boolean
// Output: onClick

const LikeBtn = (props) => {
  let classes = 'fa fa-heart';
  if (!props.liked) classes += '-o';
  return (
    <i
      className={classes}
      aria-hidden="true"
      onClick={props.onClick}
      style={{ cursor: 'pointer', color: 'red' }}
    ></i>
  );
};

export default LikeBtn;
