import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './Components/commons/protectedRoute';
import StepForm from './Components/StepperForm/stepForm';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { Login } from './pages/loginForm';
import { Register } from './pages/registerForm';
import Home from './pages/Home';
import Positions from './pages/Positions';
import Logout from './pages/logout';
import Absagen from './pages/absagen';
import Bewerber from './pages/Bewerber';
import Zusagen from './pages/zusagen';
import NotFound from './pages/notfound';
import Jobs from './pages/Jobs';
import { JopForm } from './Components/NewJobForm';
import Profile from './pages/Profile';
import Datenschutz from './pages/Datenschutz';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/bewerben/:position" element={<StepForm />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/datenschutz" element={<Datenschutz />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/zusagen" element={<Zusagen type="zusagen" />} />
            <Route path="/absagen" element={<Absagen type="absagen" />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="/jobs/:id" element={<JopForm />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/bewerber/:id" element={<Bewerber />} />
            <Route path="/absagen/:id" element={<Bewerber type="absagen" />} />
            <Route path="/zusagen/:id" element={<Bewerber type="zusagen" />} />
          </Route>

          <Route path="/not-found" element={<NotFound />} />
          <Route path="/" element={<Positions />} />
          <Route path="/:text" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
