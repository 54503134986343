import React from "react";
import { jaOderNein } from "../../utils/strings";

function UserInfos(props) {
  const { user } = props;
  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">Anrede</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{user.anrede}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">Vorname</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{user.firstname}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">Nachname</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{user.lastname}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">Email</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{user.email}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">Rufnummer</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{user.number}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">PLZ</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{user.plz}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">Führerschein</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{jaOderNein(user.fuehrerschein)}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">Ausbildung</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{jaOderNein(user.ausbildung)}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">Berufserfahrung</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{user.berufserfahrung}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0">Einstieg</p>
          </div>
          <div className="col-sm-9">
            <p className="text-muted mb-0">{user.einstieg}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserInfos;
