import React, { Component } from "react";
import { Link } from "react-router-dom";
import LikeBtn from "./commons/like";
import Table from "./commons/table/table";
import auth from "../services/authService";
import DeleteBtn from "./commons/Buttons/DeleteBtn";

class BewerberTable extends Component {
  columns = [
    {
      path: "firstname",
      label: "Vorname",
      content: (b) => (
        <Link to={`/${this.props.type || "bewerber"}/${b._id}`}>
          {b.firstname}
        </Link>
      ),
    },
    { path: "lastname", label: "Nachname" },
    { path: "position.name", label: "Arbeitsstelle" },
    { path: "ausbildung", label: "Ausbildung", bool: true },
    { path: "berufserfahrung", label: "Berufserfahrung" },
    { path: "einstieg", label: "Einstieg" },
    { path: "fuehrerschein", label: "Führerschein", bool: true },

    {
      key: "like",
      content: (user) => (
        <LikeBtn liked={user.liked} onClick={() => this.props.onLike(user)} />
      ),
    },
    {
      key: "more",
      content: (b) => (
        <Link to={`/${this.props.type || "bewerber"}/${b._id}`}>
          <button className="btn btn-primary btn-sm">
            Weitere Informationen
          </button>
        </Link>
      ),
    },
  ];
  deleteColumn = {
    key: "delete",
    content: (bewerber) => (
      <DeleteBtn onClick={() => this.props.onDelete(bewerber)} />
    ),
  };
  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }
  render() {
    const { bewerber, onSort, sortColumn } = this.props;
    // console.log("Bewerber ", bewerber);
    return (
      <Table
        columns={this.columns}
        data={bewerber}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default BewerberTable;
