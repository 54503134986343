import React from "react";
import UserAvater from "./UserAvater";

function CardLeftSide(props) {
  return (
    <div className="col-lg-4">
      <UserAvater
        user={props.user}
        btns={true}
        onReject={props.onReject}
        onAccept={props.onAccept}
      />
    </div>
  );
}

export default CardLeftSide;
