import React from 'react';

function ProfileCard(props) {
  const { user } = props;
  const avatar = require('./avatar.png');

  return (
    <div
      className="card mb-3"
      style={{
        width: '400px',
        height: '350px',
      }}
    >
      <div className="card-body text-center">
        <img
          src={avatar}
          alt="avatar"
          className="rounded-circle img-fluid"
          style={{ width: 150 }}
        />
        <h5 className="my-3">{user.name}</h5>
        {user.isAdmin && <h5 style={{ color: 'blue' }}>Admin</h5>}
        <p className="text-muted mb-1">{user.email}</p>
      </div>
    </div>
  );
}

export default ProfileCard;
