import React, { Component } from "react";
import NavBar from "../Components/Navbar";
import auth from "../services/authService";
import Bewerbungen from "../Components/bewerbungen";

class Zusagen extends Component {
  state = {};
  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }
  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        <NavBar user={user} />
        <Bewerbungen user={user} type={this.props.type} />
      </React.Fragment>
    );
  }
}

export default Zusagen;
