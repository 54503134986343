import http from "./httpService";
const apiEndPoint = "/absage";

function bewerberUrl(bewerberId) {
  return `${apiEndPoint}/${bewerberId}`;
}

export function getAbsagen() {
  return http.get(apiEndPoint);
}

export function getAbgesagter(bewerberId) {
  return http.get(bewerberUrl(bewerberId));
}
