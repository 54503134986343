import React, { useEffect } from 'react';
import Joi from 'joi-browser';
import Form from '../Components/commons/Form/form';
import * as userService from '../services/userService';
import auth from '../services/authService';
import { useNavigate } from 'react-router-dom';

class RegisterForm extends Form {
  state = {
    data: { username: '', password: '', name: '' },
    errors: {},
  };

  schema = {
    username: Joi.string().email().required().label('Username'),
    password: Joi.string().required().min(5).label('Password'),
    name: Joi.string().min(5).required().label('Name'),
  };

  doSubmit = async () => {
    try {
      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.headers['x-auth-token']);
      // this.props.history.push("/"); // issue because reload
      // window.location = "/";
      this.props.navigate(`/home`, { replace: true });
      this.props.navigate(0); // to refresh the page
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };
  render() {
    const avatar = require('../Components/Usercard/avatar.png');

    return (
      <div className="container bg-light rounded-3 p-3">
        <h1>Register</h1>
        <img
          src={avatar}
          alt="avatar"
          className="rounded-circle img-fluid"
          style={{ width: 250 }}
        />
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('username', 'Username')}
          {this.renderInput('password', 'Password', 'password')}
          {this.renderInput('name', 'Name')}
          {this.renderButton('Register')}
        </form>
      </div>
    );
  }
}

export default RegisterForm;

export function Register(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.getCurrentUser()) {
      console.log('user is loged in');
      navigate(`/home`, { replace: true });
      navigate(0); // to refresh the page
    }
  }, [navigate]);
  return <RegisterForm navigate={navigate} />;
}
