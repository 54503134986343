import React, { useContext } from "react";
import { TextField } from "@mui/material";
import { multiStepContext } from "../../context/StepContext";

function InputField({ label, valueKey, type }) {
  const { userData, setUserData } = useContext(multiStepContext);

  return (
    <div>
      <TextField
        type={type}
        label={label}
        value={userData[valueKey]}
        onChange={(e) => {
          const data = { ...userData };
          data[valueKey] = e.target.value;
          setUserData(data);
        }}
        margin="normal"
        variant="outlined"
        color="secondary"
      />
    </div>
  );
}
InputField.defaultProps = {
  type: "text",
};
export default InputField;
