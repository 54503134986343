import React, { useEffect, useState } from 'react';
import CardLeftSide from './CardLeftSide';
import CardRightSide from './CardRightSide';
import CardNavbar from './CardNavbar';
import { absagen, zusagen } from '../../services/bewerbungService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Usercard(props) {
  const { user, type } = props;
  const [bewerber, setBewerber] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setBewerber(props.user);
  }, [user]);

  const handleReject = async () => {
    try {
      await absagen(bewerber._id);
      navigate(`/absagen/${bewerber._id}`, { replace: true });
      navigate(0); // to refresh the page
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 409)
        toast.error('Email is Faild, please contact the Admin.');
    }
  };
  const handleAccept = async () => {
    try {
      await zusagen(bewerber._id);
      navigate(`/zusagen/${bewerber._id}`, { replace: true });
      navigate(0); // to refresh the page
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 409)
        toast.error('Email is Faild, please contact the Admin.');
    }
  };

  return (
    <section style={{ backgroundColor: '#eee' }}>
      <div className="container py-5">
        <CardNavbar id={user._id} type={type} />
        <div className="row">
          <CardLeftSide
            user={user}
            onReject={handleReject}
            onAccept={handleAccept}
          />
          <CardRightSide user={user} />
        </div>
      </div>
    </section>
  );
}

export default Usercard;
