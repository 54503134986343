import React from "react";

function Datenschutz() {
  return (
    <div className="bg-light rounded-3 p-3 m-4">
      <header>
        <header>
          <h2 style={{ textAlign: "center", overflowWrap: "break-word" }}>
            DATENSCHUTZERKLÄRUNG
          </h2>
        </header>
        <div>
          <div>
            <p style={{ textAlign: "center" }}>
              <strong>
                § 1 Information über die Erhebung personenbezogener Daten
              </strong>
            </p>
            <ol>
              <li>
                Im Folgenden informieren wir über die Erhebung personenbezogener
                Daten bei Nutzung unserer Website. Personenbezogene Daten sind
                alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name,
                Adresse, E-Mail-Adressen, Nutzerverhalten.
                <br />
                <br />
              </li>
              <li>
                Verantwortlicher gem. Art. 4 Abs. 7
                EU-Datenschutz-Grundverordnung (DS-GVO) ist HD Energiesysteme
                GmbH, Hagenauer Str. 53/Tor 9, 65203 Wiesbaden,
                datenschutz@hdenergiesysteme.de.
                <br />
                <br />
              </li>
              <li>
                Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
                Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre
                E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns
                gespeichert, um Ihre Fragen zu beantworten. Die in diesem
                Zusammenhang anfallenden Daten löschen wir, nachdem die
                Speicherung nicht mehr erforderlich ist, oder schränken die
                Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten
                bestehen.
                <br />
                <br />
              </li>
              <li>
                Falls wir für einzelne Funktionen unseres Angebots auf
                beauftragte Dienstleister zurückgreifen oder Ihre Daten für
                werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im
                Detail über die jeweiligen Vorgänge informieren. Dabei nennen
                wir auch die festgelegten Kriterien der Speicherdauer.
              </li>
            </ol>
            <p>
              <br />
              <strong>§ 2 Ihre Rechte</strong>
            </p>
            <ol>
              <li>
                Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
                betreffenden personenbezogenen Daten:
                <br />· Recht auf Auskunft,
                <br />· Recht auf Berichtigung oder Löschung,
                <br />· Recht auf Einschränkung der Verarbeitung,
                <br />· Recht auf Widerspruch gegen die Verarbeitung,
                <br />· Recht auf Datenübertragbarkeit.
              </li>
              <li>
                Sie haben zudem das Recht, sich bei einer
                Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                personenbezogenen Daten durch uns zu beschweren.
              </li>
            </ol>
            <p>
              <br />
              <strong>
                § 3 Erhebung personenbezogener Daten bei Besuch unserer Website
              </strong>
            </p>
            <ol>
              <li>
                Besuch unserer Website
                <br />
                Bei der bloß informatorischen Nutzung der Website, also wenn Sie
                sich nicht registrieren oder uns anderweitig Informationen
                übermitteln, erheben wir nur die personenbezogenen Daten, die
                Ihr Browser an unseren Server übermittelt. Wenn Sie unsere
                Website betrachten möchten, erheben wir die folgenden Daten, die
                für uns technisch erforderlich sind, um Ihnen unsere Website
                anzuzeigen und die Stabilität und Sicherheit zu gewährleisten
                (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO):
                <br />· IP-Adresse
                <br />· Datum und Uhrzeit der Anfrage
                <br />· Zeitzonendifferenz zur Greenwich Mean Time (GMT)
                <br />· Inhalt der Anforderung (konkrete Seite)
                <br />· Zugriffsstatus/HTTP-Statuscode
                <br />· jeweils übertragene Datenmenge
                <br />· Website, von der die Anforderung kommt
                <br />· Browser
                <br />· Betriebssystem und dessen Oberfläche
                <br />· Sprache und Version der Browsersoftware.
                <br />
                &nbsp;
              </li>
              <li>
                Cookies
                <br />
                Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung
                unserer Website <strong>KEINE</strong> Cookies auf Ihrem Rechner
                gespeichert. Bei Cookies handelt es sich um kleine Textdateien,
                die auf Ihrer Festplatte dem von Ihnen verwendeten Browser
                zugeordnet gespeichert werden und durch welche der Stelle, die
                den Cookie setzt, bestimmte Informationen zufließen. Cookies
                können keine Programme ausführen oder Viren auf Ihren Computer
                übertragen. Sie dienen dazu, das Internetangebot insgesamt
                nutzerfreundlicher und effektiver zu machen.
              </li>
              <li>
                Datensicherheit
                <br />
                Wir verwenden innerhalb des Website-Besuchs das verbreitete
                SSL-Verfahren (Secure Socket Layer) in Verbindung mit der
                jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser
                unterstützt wird. In der Regel handelt es sich dabei um eine 256
                Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit
                Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit
                v3 Technologie zurück. Ob eine einzelne Seite unseres
                Internetauftrittes verschlüsselt übertragen wird, erkennen Sie
                an der geschlossenen Darstellung des Schüssel- beziehungsweise
                Schloss-Symbols in der unteren Statusleiste Ihres Browsers. Wir
                bedienen uns im Übrigen geeigneter technischer und
                organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
                zufällige oder vorsätzliche Manipulationen, teilweisen oder
                vollständigen Verlust, Zerstörung oder gegen den unbefugten
                Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden
                entsprechend der technologischen Entwicklung fortlaufend
                verbessert.
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p>
              <br />
              Besondere Nutzungsformen von Websites
            </p>
            <ol>
              <li>
                Nutzung unseres Bewerbungsportal
                <br />
                Beim Bewerben bieten wir Ihnen die Möglichkeit, mit uns über ein
                auf der Website bereitgestelltes Formular Sich schnell zu
                bewerben. Dabei ist die Angabe einer gültigen E-Mail- Adresse,
                Vor-, Nachname, Rufnummer, Postleitzahl und ähnliche Fragen
                erforderlich, damit wir wissen, von wem die Anfrage stammt und
                um diese beantworten bzw. Das Bewerbverfahren verarbeiten zu
                können. Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit
                uns beziehungsweise zum Zwecke der Bewerbung erfolgt nach Art. 6
                Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig
                erteilten Einwilligung. Die für die Verarbeitung des
                Bewerbsverfahrens von uns erhobenen personenbezogenen Daten
                werden nach Erledigung der von Ihnen gestellten Anfrage
                innerhalb maximal 6 Monate automatisch gelöscht. Eine
                Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als
                den im Folgenden aufgeführten Zwecken findet nicht statt. Wir
                geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
                <br />· Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
                ausdrückliche Einwilligung dazu erteilt haben,
                <br />· die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                erforderlich ist und kein Grund zur Annahme besteht, dass Sie
                ein überwiegendes schutzwürdiges Interesse an der
                Nichtweitergabe Ihrer Daten haben,
                <br />· für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1
                S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie
                <br />· dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1
                lit. B DSGVO für die Abwicklung von Vertragsverhältnissen mit
                Ihnen erforderlich ist.
              </li>
            </ol>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Datenschutz;
