import React, { Component, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { multiStepContext } from "../context/StepContext";

function NavBar(props) {
  const { user } = props;
  const { show, setShow } = useContext(multiStepContext);
  // console.log(show);
  const setTypeOfShow = (type) => {
    type = show === "absagen" ? "zusagen" : "absagen";
    setShow(type);
    console.log(type);
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <Link className="navbar-brand" to="/">
        HR-Managment-System
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <div className="navbar-nav">
          <NavLink className="nav-link" to="/home">
            Bewerbungen <span className="sr-only">(current)</span>
          </NavLink>

          <NavLink className="nav-link" to="/zusagen">
            Zusagen
          </NavLink>

          <NavLink className="nav-link" to="/absagen">
            Absagen
          </NavLink>
          <NavLink className="nav-link" to="/jobs">
            Jobs
          </NavLink>

          {!user && (
            <React.Fragment>
              <NavLink className="nav-link" to="/login">
                Login
              </NavLink>
              <NavLink className="nav-link" to="/register">
                Register
              </NavLink>
            </React.Fragment>
          )}
          {user && (
            <React.Fragment>
              <NavLink className="nav-link" to="/profile">
                {user.name}
              </NavLink>
              {/* <span
                className="nav-link"
                onClick={() => setTypeOfShow("absagen")}
              >
                test
              </span> */}

              <NavLink className="nav-link" to="/logout">
                Logut
              </NavLink>
            </React.Fragment>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
