import React, { useEffect } from 'react';
import Joi from 'joi-browser';
import Form from '../Components/commons/Form/form';
import auth from '../services/authService';
import { useNavigate } from 'react-router-dom';

class LoginForm extends Form {
  state = {
    data: { username: '', password: '' },
    errors: {},
  };

  schema = {
    username: Joi.string().required().label('Username'),
    password: Joi.string().required().label('Password'),
  };

  doSubmit = async () => {
    try {
      const { username, password } = this.state.data;
      await auth.login(username, password);
      console.log('loginform');
      this.props.navigate(`/home`, { replace: true });
      this.props.navigate(0); // to refresh the page
      // // this.props.history.push("/"); // issue because reload
      // const { state } = this.props.location;
      // window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    const avatar = require('../Components/Usercard/avatar.png');

    return (
      <div className="container bg-light rounded-3 p-3">
        <h1>Login</h1>
        <img
          src={avatar}
          alt="avatar"
          className="rounded-circle img-fluid"
          style={{ width: 250 }}
        />
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('username', 'Username')}
          {this.renderInput('password', 'Password', 'password')}
          {this.renderButton('Login')}
        </form>
      </div>
    );
  }
}

export default LoginForm;

export function Login(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.getCurrentUser()) {
      console.log('user is loged in');
      navigate(`/home`, { replace: true });
      navigate(0); // to refresh the page
    }
  }, [navigate]);
  return <LoginForm navigate={navigate} />;
}
