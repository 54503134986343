import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBewerber } from '../services/bewerbungService';
import auth from '../services/authService';
import Usercard from '../Components/Usercard/Usercard';

function Bewerber(props) {
  const [bewerber, setBewerber] = useState({});
  const { id: bewerberId } = useParams();
  const { type } = props;

  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      // let { data } = await getBewerber(bewerberId);

      const user = auth.getCurrentUser();
      setUser(user);
      let { data } = await getBewerber(props.type, bewerberId);
      console.log(data);
      data = { ...data, position_name: data.position.name };
      setBewerber(data);
    };

    fetchData();
  }, [bewerberId, props.type]);

  return (
    <div>
      <Usercard user={bewerber} type={type} />
    </div>
  );
}

export default Bewerber;
