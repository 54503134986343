import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '../services/authService';
import ProfileCard from '../Components/ProfileCard/ProfileCard';
import NavBar from '../Components/Navbar';

function Profile() {
  const [user, setUser] = useState({});
  useEffect(() => {
    const data = getCurrentUser();
    setUser(data);
    // console.log(data);
  }, []);
  return (
    <div>
      <NavBar user={user} />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ProfileCard user={user} />
      </div>
    </div>
  );
}

export default Profile;
