import React from "react";
import InputButton from "../../commons/InputButton";
import NavigationButtons from "../../commons/NavigationButtons";
import { Step4Antworten, StepsFragen } from "../../../utils/strings";

function EinstiegStep(props) {
  return (
    <div>
      <h2>{StepsFragen[3]}</h2>
      <InputButton
        label={Step4Antworten[0]}
        value={Step4Antworten[0]}
        valueKey="einstieg"
      />
      <InputButton
        label={Step4Antworten[1]}
        value={Step4Antworten[1]}
        valueKey="einstieg"
      />
      <InputButton
        label={Step4Antworten[2]}
        value={Step4Antworten[2]}
        valueKey="einstieg"
      />
      <InputButton
        label={Step4Antworten[3]}
        value={Step4Antworten[3]}
        valueKey="einstieg"
      />

      <NavigationButtons />
    </div>
  );
}

export default EinstiegStep;
