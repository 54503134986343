import React, { useContext } from "react";
import { multiStepContext } from "../context/StepContext";

function Test() {
  const { show, setShow } = useContext(multiStepContext);
  const handleSubmit = () => {
    setShow("profile");
    console.log("trying");
  };
  const handleSubmit2 = () => {
    console.log(show);
  };

  return (
    <div>
      Test
      <button onClick={handleSubmit}>setIt</button>
      <button onClick={handleSubmit2}>showIt</button>
    </div>
  );
}

export default Test;
